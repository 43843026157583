import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./gndPledge.scss";
import NavBar from "../components/NavBar/NavBar";

const GndPledge = () => {
  return (
    <>
      <NavBar />
      <div className="gndPledge">
        <StaticImage
          src="../images/gnd-pledge.jpg"
          placeholder="blurred"
          alt="Green New Deal Pledge"
          className="gnd"
        />
        <div className="tagContainer">
          <span className="tags">#GreenNewDeal</span>
          <span className="tags">@sunrisemvmt</span>
        </div>
      </div>
    </>
  );
};

export default GndPledge;
